import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import { Filev1, QueryProgress } from "core/types";
import { HighlightOffIcon, UndoIcon } from "ds/icons";
import RSButton from "ds_legacy/components/RSButton";
import { MessengerTabsProps } from "ds_legacy/components/Tabs/Messenger";
import TextArea from "ds_legacy/components/TextArea";
import {
  BORDER_COLOR,
  CARD_BORDER,
  ERROR_COLOR,
  UNAVAILABLE_GREY,
  WHITE,
} from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import {
  TABS_HEIGHT,
  border,
  dp,
  margin,
  padding,
  sizing,
  space,
} from "ds_legacy/materials/metrics";
import { FONT_SIZE_16, Title } from "ds_legacy/materials/typography";
import { useEncryptionKey } from "dsl/atoms/Contexts";
import { decrypt, isValidFile } from "dsl/atoms/FileUploader/model";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import {
  DialogContentWrapper,
  ProviderSearchDialog,
} from "dsl/ecosystems/ProviderSearchLoginModal/shared";
import { RESPONSIVE_BREAKPOINT_ZOOM_200 } from "dsl/molecules/Messenger/Layouts/MessengerLayout";
import { usePanda } from "pandatracker-client";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import request from "superagent";
import { useTranslations } from "translations";

export const BANNER_PADDING = 5;
export const BANNER_WIDTH = 440;

export const MessengerPositioner = styled(VerticalLayout)<{
  activateFilesFeature: boolean;
  activeTab: MessengerTabsProps["activeTab"];
  fullWidth?: boolean;
  isDesktop?: boolean;
}>`
  background: white;
  position: ${({ isDesktop }) => (isDesktop ? "sticky" : "static")};
  height: calc(100vh - ${TABS_HEIGHT} - ${space(4)});
  top: ${dp(16)};
  left: ${dp(0)};
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : dp(BANNER_WIDTH))};
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  border: ${({ activateFilesFeature, activeTab, isDesktop }) =>
    (activeTab === "files" || !activateFilesFeature) && isDesktop
      ? border({ color: CARD_BORDER })
      : "none"};
`;

export const PAGE_COLUMN_GAP = sizing(2);
export const AuctionPageLayout = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1 0 0px;
  gap: ${PAGE_COLUMN_GAP};
  justify-content: center;
  overflow-y: auto;
  padding: ${padding(0, 2)};
  position: relative;

  @media print {
    overflow: visible;
  }
`;

export const PageLeftSide = styled.div`
  box-sizing: border-box;
  max-width: calc(100vw - ${dp(BANNER_WIDTH)} - ${PAGE_COLUMN_GAP});
  display: flex;
`;

export const BannerActionsBox = styled.div<{
  flexGrow?: boolean;
  isMobile?: boolean;
}>`
  align-items: center;
  box-shadow: 0px 6px 27px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  flex-grow: ${({ flexGrow }) => (flexGrow ? 1 : 0)};
  height: ${({ isMobile }) => isMobile && dp(48)};
`;

export const BackButton = ({
  onClick,
}: {
  onClick: (...arg: Array<ToType>) => void;
}) => {
  const translations = useTranslations();

  return (
    <RSButton
      id="back"
      loading="na"
      onClick={onClick}
      style={{ ...bannerActionStyle, maxHeight: dp(36), minWidth: dp(120) }}
      variant="text"
    >
      {translations.actions.back}
    </RSButton>
  );
};

export function DownloadButton({
  file,
  right,
}: {
  file: Filev1;
  right?: boolean;
}) {
  const { link } = file;
  const translations = useTranslations();
  const decryptedSessionKey = useEncryptionKey();
  const toast = useToast();

  async function handleDownload() {
    try {
      const response = await request("GET", link as string).set(
        "Accept",
        "*/*",
      );
      await decrypt({
        content: response.text,
        seald_content: undefined,
        decryptedSessionKey: decryptedSessionKey as CryptoKey,
        file,
      });
    } catch (e) {
      console.error(e);
      toast({
        message: translations.auctionRequest.tryAgain,
        color: "primary",
      });
    }
  }

  if (!decryptedSessionKey || !isValidFile(file) || !link) {
    return null;
  }

  return (
    <ProviderCancelButtonWrapper alignStart={!right}>
      <RSButton
        id="download_file"
        loading="na"
        onClick={handleDownload}
        variant="outlined"
      >
        {translations.actions.download}
      </RSButton>
    </ProviderCancelButtonWrapper>
  );
}

const EventActionsPositioner = styled.div`
  box-sizing: border-box;
  width: 100%;
  background: ${WHITE};
  border-top: ${border({ color: BORDER_COLOR })};
  padding: ${padding(1)};
`;

export function EventActionsWrapper({ children }: { children: ReactNode }) {
  const { isDesktop } = useMedia();
  return (
    <EventActionsPositioner>
      <Box
        style={{
          boxSizing: "border-box",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-end",
          padding: isDesktop ? padding(0, 0, 1, 0) : padding(2),
        }}
      >
        {children}
      </Box>
    </EventActionsPositioner>
  );
}

export const bannerActionStyle = {
  margin: margin(1, 0),
  minWidth: dp(120),
};

const ButtonsContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  padding: ${padding(1)};
  gap: ${padding(2)};
`;

const ButtonPanel = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
`;

export function Buttons({ children }: { children?: ReactNode }) {
  return (
    <ButtonPanel>
      <ButtonsContainer>{children}</ButtonsContainer>
    </ButtonPanel>
  );
}

const CancelButtonWrapper = styled.div<{ visible?: boolean }>`
  cursor: pointer;
  display: flex;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  padding: ${padding(2, 0, 0, 0)};
  transition: opacity 0.3s ease;
`;

const ProviderCancelButtonWrapper = styled.div<{
  alignStart?: boolean;
}>`
  align-items: ${({ alignStart }) => (alignStart ? "flex-start" : "flex-end")};
  display: flex;
  flex-direction: column;
  padding: ${padding(0.5, 0, 0, 0)};
`;

export function CancelButton({
  onCancel,
  onSubmitQueryProgress,
  visible,
  withButton,
}: {
  onCancel: () => void;
  onSubmitQueryProgress?: QueryProgress;
  visible?: boolean;
  withButton?: boolean;
}) {
  const { isTablet } = useMedia();
  const [open, setOpen] = useState(false);
  const translations = useTranslations();

  async function handleConfirm() {
    await onCancel();
    setOpen(false);
  }

  const actions = [
    <RSButton
      id="back"
      loading="na"
      variant="text"
      key="backButton"
      color="primary"
      onClick={() => setOpen(false)}
    >
      {translations.actions.back}
    </RSButton>,
    <RSButton
      color="primary"
      id="confirm"
      key="confirm"
      loading={onSubmitQueryProgress || "na"}
      onClick={handleConfirm}
      variant="contained"
    >
      {translations.actions.confirm}
    </RSButton>,
  ];

  return withButton ? (
    <>
      <ProviderCancelButtonWrapper>
        <RSButton
          className="cancel"
          color="primary"
          id="cancel_action"
          key="cancel-action"
          LeftIcon={UndoIcon}
          loading="na"
          onClick={() => setOpen(true)}
          size="small"
          variant="outlined"
        >
          {translations.actions.undo}
        </RSButton>
      </ProviderCancelButtonWrapper>
      {open && (
        <ProviderSearchDialog onClose={() => setOpen(false)}>
          <DialogContentWrapper testId="undo-confirm-modal">
            <DialogTitle textAlign="center" sx={{ paddingTop: 0 }}>
              {translations.auctionRequest.cancelConfirmation}
            </DialogTitle>
          </DialogContentWrapper>
          <DialogActions
            sx={{ justifyContent: isTablet ? "center" : "flex-end" }}
          >
            {actions}
          </DialogActions>
        </ProviderSearchDialog>
      )}
    </>
  ) : (
    <>
      <CancelButtonWrapper visible={visible}>
        <div>
          <Tooltip title={translations.actions.undo}>
            <div>
              <HighlightOffIcon
                id="cancel_action"
                style={{ color: ERROR_COLOR }}
                onClick={() => setOpen(true)}
                size={FONT_SIZE_16}
              />
            </div>
          </Tooltip>
        </div>
      </CancelButtonWrapper>
      {open && (
        <ProviderSearchDialog onClose={() => setOpen(false)}>
          <DialogContentWrapper testId="undo-confirm-modal">
            <DialogTitle textAlign="center" sx={{ paddingTop: 0 }}>
              {translations.auctionRequest.cancelConfirmation}
            </DialogTitle>
          </DialogContentWrapper>
          <DialogActions
            sx={{ justifyContent: isTablet ? "center" : "flex-end" }}
          >
            {actions}
          </DialogActions>
        </ProviderSearchDialog>
      )}
    </>
  );
}

export const MessengerFormTitle = ({
  accent = false,
  children,
  disabled = false,
  error = false,
  id,
  primary = false,
  success = false,
}: {
  accent?: boolean;
  children: string | null | undefined;
  disabled?: boolean;
  error?: boolean;
  id: string;
  primary?: boolean;
  success?: boolean;
}) => (
  <Title
    id={id}
    accent={accent}
    as="h2"
    color={disabled ? UNAVAILABLE_GREY : undefined}
    dark
    error={error}
    margin={margin(2, 2, 1)}
    primary={primary}
    success={success}
  >
    {children}
  </Title>
);

export const BannerTextAreaBox = styled.div`
  display: flex;
  flex: 1 0 auto;
  margin: ${margin(1, BANNER_PADDING - 2)};
`;

export function BannerTextArea(props: ToType) {
  return (
    <BannerTextAreaBox>
      <TextArea
        rows={9}
        width={`calc(100% - ${space(BANNER_PADDING - 3.5)})`}
        {...props}
      />
    </BannerTextAreaBox>
  );
}

const BannerMessagingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const BannerScrollable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  @media (max-height: ${dp(RESPONSIVE_BREAKPOINT_ZOOM_200)}) {
    padding-bottom: 100px;
  }
`;

export function BannerMessaging({ children }: { children: ReactNode }) {
  return (
    // tabIndex 0 -> see https://recare.atlassian.net/browse/DEV-13450
    <BannerScrollable tabIndex={0}>
      <BannerMessagingWrapper>{children}</BannerMessagingWrapper>
    </BannerScrollable>
  );
}

export function BannerEventsPlaceholder() {
  usePanda("MessengerEvents");
  return null;
}
