import { SEARCH_TYPE_TRANSPORT } from "core/consts";
import { hasMobileSolution } from "core/model/patients";
import { Auction, SearchType } from "core/types";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin } from "ds_legacy/materials/metrics";
import { Caption } from "ds_legacy/materials/typography";
import MapActivation from "dsl/molecules/GoogleLocationMap/Activation";
import LocationMap from "dsl/molecules/GoogleLocationMap/Lazy";
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { usePatientInfoContext } from "./index";
import { LocationContent } from "./shared";

const HideOnPrint = styled.div`
  @media print {
    display: none;
  }
`;

const MAP_WIDTH = dp(500);
const MAP_HEIGHT = dp(300);

const Wrapper = ({
  Card,
  children,
  searchType,
  translations,
}: {
  Card: any;
  children: any;
  searchType: SearchType | null | undefined;
  translations: Translations;
}) =>
  searchType === SEARCH_TYPE_TRANSPORT ? (
    <>{children}</>
  ) : (
    <Card noDivider title={translations.patient.treatmentLocation.title}>
      {children}
    </Card>
  );

function getLocation(auction: Auction | undefined | null) {
  return auction?.profile?.search_location || null;
}

function getDestination({ auction }: { auction: Auction }) {
  const destinationLocation = auction.profile?.search_destination;

  return destinationLocation?.latitude && destinationLocation?.longitude
    ? {
        lat: destinationLocation.latitude,
        lng: destinationLocation.longitude,
      }
    : undefined;
}

export default function TreatmentLocationCard({
  auction,
  component,
  oldAuction,
  show,
}: {
  auction: Auction;
  component: string;
  oldAuction?: Auction;
  show: boolean;
}) {
  const translations = useTranslations();
  const [showMap, setShowMap] = useState(false);

  const destination = getDestination({ auction });
  const { Card } = usePatientInfoContext();

  if (!show) {
    return null;
  }

  const location = getLocation(auction);
  const oldLocation = getLocation(oldAuction);

  if (!location) {
    return null;
  }

  const onlyZipcodeProvided =
    !!location.zipcode && !location.city && !location.street;
  if (onlyZipcodeProvided) {
    return null;
  }

  const withDiff = !!oldAuction;
  if (withDiff ? !location && !oldLocation : !location) {
    return null;
  }

  const displayMap =
    location?.longitude && location.latitude && location.radius_in_meter != 0;

  return (
    <HideOnPrint>
      <div data-testid="treatment-location-card">
        <Wrapper
          Card={Card}
          translations={translations}
          searchType={auction.search_type}
        >
          {displayMap && (
            <VerticalLayout margin={margin(0, 0, 1, 0)}>
              <Caption whiteSpace="normal">
                {destination
                  ? translations.auctionRequest.mapCaption
                  : translations.patient.treatmentLocation
                      .privacyDescriptionEncrypted}
              </Caption>
            </VerticalLayout>
          )}
          {hasMobileSolution(auction.solutions || []) && (
            <LocationContent
              location={location}
              oldLocation={oldLocation}
              withDiff={withDiff}
              translations={translations}
            />
          )}
          {displayMap && (
            <HideOnPrint>
              <VerticalLayout margin={margin(2)}>
                {showMap ? (
                  <LocationMap
                    component={component}
                    className="chromaticos-ignore"
                    latitude={location.latitude!}
                    longitude={location.longitude!}
                    destination={destination}
                    radius={location.radius_in_meter}
                    withCircle
                    width={MAP_WIDTH}
                    height={MAP_HEIGHT}
                  />
                ) : (
                  <MapActivation
                    width={MAP_WIDTH}
                    height={MAP_HEIGHT}
                    title={translations.patient.map.mapReceiverText}
                    onActivate={() => setShowMap(true)}
                  />
                )}
              </VerticalLayout>
            </HideOnPrint>
          )}
        </Wrapper>
      </div>
    </HideOnPrint>
  );
}
